<template>
  <tr>
    <td>
      <div class="img-container">
        <JobboardIcon :name="product.icon" size="4" />
      </div>
    </td>
    <td class="td-name mb-1">
      <div class="text-primary">{{ product.title[lg] }}</div>
      <small>{{ product.description[lg] }}</small>
    </td>
    <td></td>

    <td></td>
    <td class="td-number">
      <div>{{ productDisplayPricing(product.id) }}</div>
    </td>
    <td class="td-number">
      {{ displayQuantity }}
      <div v-if="!product.is_unlimited" class="btn-group btn-group-sm">
        <button
          @click.prevent="downQuantity(product.id)"
          class="btn btn-outline-default btn-round"
        >
          <i class="nc-icon nc-simple-delete"></i>
        </button>
        <button
          @click.prevent="upQuantity(product.id)"
          class="btn btn-outline-default btn-round"
        >
          <i class="nc-icon nc-simple-add"></i>
        </button>
      </div>
    </td>
    <td class="td-number">
      <small>$</small>{{ productIntervalAmount(product.id) * product.quantity }}
    </td>
    <td class="td-actions">
      <button
        @click.prevent="removeProduct(product.id)"
        type="button"
        rel="tooltip"
        data-placement="left"
        title=""
        class="btn btn-neutral"
        data-original-title="Remove item"
      >
        <i class="nc-icon nc-simple-remove"></i>
      </button>
    </td>
  </tr>
</template>
<script>
import JobboardIcon from "@/components/JobboardIcon";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: { JobboardIcon },
  computed: {
    ...mapGetters("me", ["lg"]),
    ...mapGetters("cart", [
      "productIntervalAmount",
      "productDisplayPricing",
      "getStripeInterval"
    ]),
    displayQuantity() {
      return this.product.is_unlimited
        ? this.$gettext("Unlimited")
        : this.product.quantity * this.product.step;
    }
  },
  methods: {
    ...mapMutations("cart", ["upQuantity", "downQuantity", "removeProduct"])
  }
};
</script>
