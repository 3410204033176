<template>
  <div>
    <div class="page-banner" v-if="!hasUpdatableSubscription">
      <StripeIntervalToggler />
    </div>
    <div class="page-content">
      <div class="row">
        <EmptyState
          v-if="isEmpty"
          :to="{ name: 'boutique' }"
          icon="jbsmd-cart"
          :message="$gettext('Your cart is empty')"
          :customButtonText="$gettext('Add a product')"
        ></EmptyState>
        <div v-else class="col-md-12">
          <ContentHeader :title="$gettext('My Cart')">
            <template #description>
              <div>
                {{
                  $gettext(
                    "*Please note that all of our plans renew automatically and can be canceled at any time."
                  )
                }}
              </div>
              <div>
                {{
                  $gettext(
                    "All Jobs Network products include our Partners Network for free"
                  )
                }}
              </div>
            </template>
          </ContentHeader>

          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-shopping">
                  <thead class="">
                    <tr>
                      <th>
                        <translate>Product</translate>
                      </th>
                      <th class="text-center"></th>
                      <th></th>
                      <th></th>
                      <th class="text-right">
                        <translate>Price</translate>
                      </th>
                      <th class="text-right">
                        <translate>Quantity</translate>
                      </th>
                      <th class="text-right">
                        <translate>Amount</translate>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <CartProductRow
                      v-for="(product, index) in cartProducts"
                      :key="index"
                      :product="product"
                    />

                    <tr>
                      <td colspan="3"></td>
                      <td class="td-total">
                        Total
                      </td>
                      <td class="td-price">
                        <div>
                          <small>$</small> {{ Number(monthlySubTotal) }} /
                          <translate>Month</translate>
                        </div>
                        <small
                          v-if="stripeInterval === 'year'"
                          class="billed-annualy text-muted ml-2"
                        >
                          <translate> *Billed annually</translate>
                        </small>
                      </td>
                      <td colspan="3" class="text-right">
                        <router-link
                          to="/checkout"
                          class="btn btn-primary btn-round"
                        >
                          <translate>Complete Purchase</translate>
                          <i class="nc-icon nc-minimal-right ml-2"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CartProductRow from "@/views/Boutique/CartProductRow";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import StripeIntervalToggler from "@/views/Boutique/components/StripeIntervalToggler";
export default {
  components: {
    StripeIntervalToggler,
    EmptyState,
    CartProductRow,
    ContentHeader
  },
  async created() {
    await this.fetchSubscription();
    await this.initCart();
    await this.stopFetching();
  },
  computed: {
    ...mapGetters("cart", {
      stripeInterval: "getStripeInterval",
      cartProducts: "products",
      subTotal: "subTotal",
      isEmpty: "isEmpty",
      monthlySubTotal: "monthlySubTotal"
    }),
    ...mapGetters("subscriptions", ["hasUpdatableSubscription"])
  },
  methods: {
    ...mapActions("cart", { initCart: "init" }),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("subscriptions", { fetchSubscription: "fetch" })
  }
};
</script>
